








































































import { CreateTypeEnum } from "../../includes/logic/Modules/types";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { successNotification } from "@/includes/NotificationService";

import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  data() {
    return {
      CreateTypeEnum
    }
  }
})
export default class DashboardModulesCard extends Vue {
  @Prop({ type: Boolean }) needMigrate!: boolean

  @Prop({ type: Boolean }) isConfigDeprecated!: boolean

  @Emit()
  chatMigrated() {
    return null
  }

  YELLOW = 'rgb(255, 237, 134)'

  isMigrateModuleModalOpen = false

  get moduleManager() {
    return ModuleManagerState
  }

  doMigrate() {
    this.$store.dispatch('migrateChatToModules', this)
      .then(res => {
        if (res) {
          this.isMigrateModuleModalOpen = false

          this.$baseTemplate.sidebar.reload()

          successNotification(this.$t('modules_migrate_success_message').toString())

          this.chatMigrated()
        }
      })
  }
}

