import i18n from "@/i18n/i18n";
import { ChatMetrics } from "@/includes/types/DashboardExtended/types";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";
import ModuleBuildersFactory from "@/includes/logic/Modules/modules/ModuleBuildersFactory";

import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import { sum, sumBy } from "lodash";
import moment from "moment";

let filtersState: Pick<ChatMetrics, 'filters' | 'period_start' | 'period_end'> | null = null

export function filtersStatistics(metrics?: ChatMetrics) {
  if (metrics) {
    if (filtersState === null) {
      filtersState = {
        filters: {},
        period_start: '',
        period_end: ''
      }
    }

    filtersState.filters = metrics.filters
    filtersState.period_start = metrics.period_start
    filtersState.period_end = metrics.period_end
  }

  function activeFilters() {
    return (ModuleManagerState?.modules ?? []).filter(m => m instanceof FilterModule) as Array<FilterModule>
  }

  function getFiltersStatistic() {
    if (filtersState) {
      return activeFilters()
          .slice()
          .map((module) => {
            const filterStat = filtersState!.filters[module.snakeCaseFilterType]
            const series = chartSeries(filterStat)

            const builder = ModuleBuildersFactory.getBuilder(module.type)

            return {
              moduleType: module.type,
              titleKey: module.titleKey,
              icon: builder?.icon,
              total: sum(Object.values(filterStat ?? [])),
              series: series
            }
          })
          .sort((a, b) => sum(b.series[0].data) - sum(a.series[0].data))
    }

    return []
  }

  function totalFiltersFired() {
    return sumBy(getFiltersStatistic(), (filter) => filter.total)
  }

  function mostActiveFilter() {
    if (totalFiltersFired() > 0) {
      const filterType = getFiltersStatistic().sort(((a, b) => b.total - a.total))[0].titleKey

      return i18n.t(filterType)
    }

    return "-"
  }

  function getTimeTicks() {
    if (filtersState) {
      return fromToTicks(filtersState.period_start, filtersState.period_end)
    }

    return fromToTicks(moment().add(-1, 'month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
  }

  function chartSeries(stat: Record<string, number> | undefined) {
    const { timeTicks, dataTicks } = getTimeTicks()

    if (stat) {
      timeTicks.forEach((tick, index) => {
        if (stat[tick]) {
          dataTicks[index] = stat[tick]
        }
      })
    }

    return [
      {
        name: i18n.t('filter_fired_times').toString(),
        data: dataTicks
      }
    ]
  }

  function filtersFiringStat() {
    const { mapTimeData } = getTimeTicks()

    if (filtersState && filtersState.filters) {
      Object.values(filtersState.filters).forEach(date => {
        Object.entries(date).forEach(([ date, value ]) => {
          mapTimeData[date] += value
        })
      })
    } else {
      console.error(`filtersState is null`)
    }

    return [ {
      data: Object.values(mapTimeData),
      name: i18n.t('dashboard_statistics_filters_executions')
    } ]
  }

  return {
    activeFilters,
    getFiltersStatistic,
    totalFiltersFired,
    mostActiveFilter,
    filtersState,
    getTimeTicks,
    filtersFiringStat
  }
}
